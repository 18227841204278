<kendo-multicolumncombobox
  class="w-100"
  #list
  [data]="data"
  [listHeight]="300"
  [textField]="textField"
  [valueField]="valueField"
  [filterable]="true"
  (filterChange)="handleSearchChanged($event)"
  [(ngModel)]="value"
  [valuePrimitive]="valuePrimitive"
  [popupSettings]="{ width: width, popupClass: 'multicolumn-combobox' }"
  [placeholder]="placeholder"
  [disabled]="disabled"
  (opened)="expandDropdown()"
  (selectionChange)="selectionChange($event)"
  [itemDisabled]="disableFn"
  >
  <ng-container *ngFor="let column of columns">
    <kendo-combobox-column
      [field]="column.field"
      [title]="column.title"
      [headerStyle]="column.widthStyle"
      [style]="column.widthStyle">
      <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
        <span>{{ dataItem[column.field] }}</span>
      </ng-template>
    </kendo-combobox-column>
  </ng-container>

  <kendo-combobox-column
    *ngIf="rowActions?.length"
    [class]="{'actionsHeader': true}"
  >
    <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
      <div class="rowaction-container">
        <prism-button
          *ngFor="let action of rowActions"
          class="mr-1"
          [icon]="action.icon"
          [size]="'small-icon'"
          [type]="action.type"
          (clicked)="action.callback(dataItem)"
        >
          {{action.text}}
        </prism-button>
      </div>
    </ng-template>
  </kendo-combobox-column>
</kendo-multicolumncombobox>
