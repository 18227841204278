<kendo-dialog
  class="dialog-component full-width-dialog"
  *ngIf="opened"
  [minWidth]="250"
  width="100%">
  <div class="titlebar">
    <div class="dialog-title header-content">
      <span *ngIf="titleIcon" class="k-icon k-font-icon" [ngClass]="'k-i-' + titleIcon + (titleIconType ? ' ' + titleIconType : '')"></span>
      {{title}}
    </div>
    <div *ngIf="headerActions" class="action-bar">
      <prism-button
        *ngFor="let action of headerActions"
        [type]="!!action.type ? action.type : 'secondary'"
        [disabled]="!!action.disabled"
        [icon]="action.icon"
        (clicked)="action.callback()"
        class="prism-button"
        [nativeType]="action.nativeType">
        {{action.text}}
      </prism-button>
    </div>
  </div>
  <ng-content></ng-content>
</kendo-dialog>
