<kendo-treelist
  *ngIf="columns?.length"
  kendoTreeListExpandable
  [loading]="isLoading"
  [expandable]="true"
  [selectable]="selectable"
  [data]="data"
  [height]="height"
  [hasChildren]="hasChildren"
  [fetchChildren]="handleFetchChildren.bind(this)"
  [isSelected]="isSelected.bind(this)"
  (selectionChange)="handleRowClick($event)">
  <kendo-treelist-checkbox-column 
    *ngIf="!selectable?.readonly && selectable?.useCheckboxes"
    [checkChildren]="true"
    [width]="55">
  </kendo-treelist-checkbox-column>
  <kendo-treelist-column *ngFor="let column of columns"
    [expandable]="column.expandable"
    [title]="column.title"
    [field]="column.field"
    [width]="getColumnWidth(column)">
    <ng-template kendoTreeListCellTemplate let-dataItem let-loading [ngSwitch]="column.type">
      <span class="px-1" *ngIf="dataItem.loadingChildren && column.expandable">
        <kendo-loader
          type="infinite-spinner"
          color="primary"
          size="small">
        </kendo-loader>
      </span>
      <span *ngSwitchCase="'icon-text'">
        <span *ngIf="dataItem.iconClass"
          [class]="dataItem.iconClass"></span>
        <img *ngIf="dataItem.iconImgSrc"
          class="img-responsive"
          [style.height.px]="dataItem.iconImgHeight"
          [src]="dataItem.iconImgSrc"
          alt="">
        {{dataItem.item[column.field]}}
      </span>
      <span *ngSwitchCase="'pill'">
        <prism-pill [status]="dataItem.item[column.field]"></prism-pill>
      </span>
      <span *ngSwitchCase="'text'">
        {{dataItem.item[column.field]}}
      </span>
      <span *ngSwitchDefault>{{dataItem.item[column.field]}}</span>
    </ng-template>
  </kendo-treelist-column>
</kendo-treelist>

<prism-modal title="Unselectable Items"
  [opened]="showModal"
  (cancel)="closeModal()"
  [actions]="modalActions">
  The following items have no {{minLevelText}}s and cannot be selected:
  <ul>
    <li *ngFor="let node of unselectableNodes">
      {{getItemTypeText(node.itemType)}} {{node.item[nameProperty]}}
    </li>
  </ul>
</prism-modal>
