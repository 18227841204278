import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DateTime, DateTimeFormatOptions, DateTimeOptions, LocaleOptions } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {

  constructor(@Inject(LOCALE_ID) public localeId: string) { }

  public fromISO(isoDate: string): Date {
    if (isoDate && isoDate.length > 0) {
      return DateTime.fromISO(isoDate).toJSDate();
    } else {
      return void 0;
    }
  }

  public fromLocalDate(localDate: string): Date {
    if (!isNaN(Date.parse(localDate))) {
      return new Date(localDate);
    } else {
      return void 0;
    }
  }

  public fromFormat(localeDate: string, format: string, opts?: DateTimeOptions): Date {
    return DateTime.fromFormat(localeDate, format, opts).toJSDate();
  }

  public toISO(date: Date): string {
    if (date) {
      return DateTime.fromJSDate(date).toISO();
    } else {
      return void 0;
    }
  }

  public toUTC(date: Date): string {
    return DateTime.fromJSDate(date).toUTC().toISO({ suppressMilliseconds: true });
  }

  public toISODate(date: Date): string {
    if (date) {
      return DateTime.fromJSDate(date).toISODate();
    } else {
      return void 0;
    }
  }

  public fromISOWithoutTime(isoDate: string): Date {
    if (isoDate && isoDate.length > 0) {
      return DateTime.fromISO(isoDate.split('T')[0]).toJSDate();
    } else {
      return void 0;
    }
  }

  public removeTimeFromDate(dateString: string | undefined): string | null {
    return dateString ? dateString.split('T')[0] : null;
  }

  public toLocaleString(date: Date, options?: DateTimeFormatOptions): string {
    return DateTime.fromJSDate(date).setLocale(this.localeId).toLocaleString(options);
  }

  public toRangeString(dateFrom: Date, dateTo: Date): string {
    let from = 'No Start';
    let to = 'No End';
    if (dateFrom) {
      from = DateTime.fromJSDate(dateFrom).toLocaleString({ month: 'short', year: 'numeric' });
    }
    if (dateTo) {
      to = DateTime.fromJSDate(dateTo).toLocaleString({ month: 'short', year: 'numeric' });
    }
    return `${from} - ${to}`;
  }

  public compareDateGtrDate(date1: string, date2: string): boolean {
    return DateTime.fromISO(date1) > DateTime.fromISO(date2);
  }

  public addDay(date: Date, daysToAdd = 1): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToAdd);
    return newDate;
  }

  public addEndOfDayTime(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 0);
    return newDate;
  }

  public addStartOfDayTime(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  public setDateFormat(date) {
    const inputDateString =  date;
    const inputDate = new Date(inputDateString);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
}
