import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GridColumn, GridNoData } from '../../shared-interfaces';

@Component({
  selector: 'prism-grouped-grid',
  templateUrl: './grouped-grid.component.html',
  styleUrls: ['./grouped-grid.component.scss']
})
export class GroupedGridComponent implements OnInit {
  @Input() data: any[];
  @Input() columns: GridColumn[];

  @Input() useParentHeight = false;
  @Input() height: number;
  @Input() gridPadding = 275;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.getDefaultGridHeight();
  }

  getHeaderStyle(columnStyle: {[key: string]: string}): {[key: string]: string} {
    return {
      ...columnStyle,
      'background-color': '#F6F6F6',
      'text-transform': 'uppercase'
    }
  }

  getStyle(columnStyle: {[key: string]: string}): {[key: string]: string} {
    if (columnStyle?.bold) {
      if (columnStyle.bold === 'true') {
        columnStyle = {
          ...columnStyle,
          'font-weight': '700'
        }
        delete columnStyle.bold;
      }
    }
    return columnStyle;
  }

  private getDefaultGridHeight(): void {
    let layout;
    if (this.useParentHeight) {
      layout = this.elementRef.nativeElement.parentNode;
      this.height = (layout.clientHeight);
    } else {
      layout = this.elementRef.nativeElement.closest('.layout-container');
      if (layout) {
        this.height = (layout.clientHeight - this.gridPadding);
      }
    }
  }
}
