import { Injectable } from '@angular/core';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateHelperService } from '../date-helper/date-helper.service';
import { UIActivityAction } from './activity-log.interface';
import {
  ActivityModel,
  ChangeItemModel,
  ChangeLogFilter,
  ChangeLogRequestConfigI,
  ChangeLogServiceI,
  ChangeModel
} from './change-log.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {

  constructor(
    private dateHelperService: DateHelperService
  ) { }

  public filterActivityChange(filter: ChangeLogFilter, logs: Array<UIActivityAction>): Array<UIActivityAction> {
    if (!filter || !Array.isArray(logs)) {
      return logs;
    }

    return logs.filter(log => this.matchesFieldNameOrValue(log, filter.fieldNameValue) &&
                              this.matchesUser(log, filter.userType)
        &&
        (filter.orderStatus
        ? this.matchesOrderStatus(log, filter.orderStatus)
        : null) &&
      (filter.activityType
      ? this.matchesActivityType(log, filter.activityType)
      : null ) &&
      (filter.status
      ? this.matchesStatus(log, filter.status)
      : null)
    );
  }

  private matchesFieldNameOrValue(log: UIActivityAction, value: string): boolean {
    if (!value) {
      return true;
    }

    value = value.toLowerCase();
    return log.activity.toLowerCase().includes(value) ||
           log.originalValue.toLowerCase().includes(value) ||
           log.newValue.toLowerCase().includes(value) ||
           log.changedBy.toLowerCase().includes(value) ||
           log.context?.toLowerCase().includes(value);
  }

  private matchesStatus(log: UIActivityAction, statuses: string[]): boolean {
    if (!statuses || statuses?.length === 0) {
      return true;
    }

    return statuses.some(status => log.status === status);
  }

  private matchesActivityType(log: UIActivityAction, activityTypes: string[]): boolean {
    if (!activityTypes || activityTypes?.length === 0) {
      return true;
    }

    const logActivityType = log.activityType.toLowerCase();

    return activityTypes.some(actType => {
      if (actType === 'manual') {
        return ['create', 'update', 'delete'].includes(logActivityType);
      }

      return logActivityType === actType;
    });
  }

  private matchesUser(log: UIActivityAction, changedBy: string[]): boolean {
    if (!changedBy || changedBy?.length === 0) {
      return true;
    }
    const logUserType = log.changedBy.split('/')[1]?.toLowerCase();
    return changedBy.some((userType) => {
      return logUserType === userType;
    });
  }

  private matchesOrderStatus(log: UIActivityAction, orderStatus: string[]): boolean {
    if (!orderStatus.length) {
      return true;
    }
    return orderStatus.some((status) => {
      return log.activity.toLowerCase() === status.toLowerCase() ||
        log.originalValue.toLowerCase() === status.toLowerCase() ||
        log.newValue.toLowerCase() === status.toLowerCase();
    });
  }

  public fetchLogs(service: ChangeLogServiceI, config: ChangeLogRequestConfigI, entity: string,
                   contextName?: string): Observable<Array<UIActivityAction>> {
    return service.fetchLogs(config)
      .pipe(map(response => {
        return Array.isArray(response) ?
          this.transformLogs(response, service, entity, contextName) :
          [];
      }));
  }

  public fetchActivity(service: ChangeLogServiceI, config: ChangeLogRequestConfigI): Observable<Array<UIActivityAction>> {
    return service.fetchActivity(config)
      .pipe(map(response => {
        return Array.isArray(response) ?
          this.transformActivity(response, service) :
          [];
      }));
  }

  public transformActivity(activity: Array<ActivityModel>, service: ChangeLogServiceI): Array<UIActivityAction> {
    return activity.map(action => {
      return {
        id: action.id,
        changeTS: action.actionTS,
        changedBy: action.actionBy,
        activity: action.actionMsg,
        originalValue: 'NA',
        newValue: 'NA',
        status: action.actionStatus.toLowerCase(),
        activityType: _.startCase(action.actionType.toLowerCase())
      };
    });
  }

  public getEntityName(service: ChangeLogServiceI, log: ChangeModel, entity: string): string {
    if (typeof service.getEntityName !== 'function') {
      return entity || log.domainName;
    }
    return service.getEntityName(log);
  }

  public transformLogs(logs: Array<ChangeModel>, service: ChangeLogServiceI, entity: string,
                       contextName?: string): Array<UIActivityAction> {
    return logs.map(log => {
      switch (log.action) {
        case 'create': {
          const entityName = this.getEntityName(service, log, entity);
          const context = this.mapContextName(log, contextName);
          return [{
            id: log.id,
            changeTS: log.changeTS,
            changedBy: log.changedBy,
            activity: `${entityName} was created`,
            rowNum: 'NA',
            originalValue: 'NA',
            newValue: 'NA',
            status: 'success',
            activityType: 'Create',
            context: `${context}`
          }];
        }
        case 'update':
        case 'delete':
          const transformed: Array<UIActivityAction> = [];
          for (const change of log.changes) {
            if (!this.excludeChanges(service, change.path)) {
              transformed.push(...this.mapUpdateChange(log, change, service, contextName));
            }
          }
          return transformed;
        default:
          return [];
      }
    }).reduce((previous, current) => previous.concat(current), []);
  }
  private mapRowNumber(change: ChangeItemModel): string {
    if (!isNaN(parseInt(change.path[1]))) {
      const rowNum = Number(change.path[1]) + 1;
      return `${rowNum}`;
    } else {
      return '';
    }
  }

  private mapContextName(log: ChangeModel, contextName: string): string {
    let context = '';
    contextName?.split(',').forEach(ctx => {
      const ctxVal = log.afterContextVal ? log.afterContextVal[ctx] : log.beforeContextVal ? log.beforeContextVal[ctx] : null;
      if (ctxVal) {
        if (context !== '') {
          context = context.concat(', ');
        }
        context = context.concat(ctxVal);
      }
    });

    return context;
  }

  /* tslint:disable*/
  private mapUpdateChange(log: ChangeModel, change: ChangeItemModel, service: ChangeLogServiceI,
                          contextName?: string): Array<UIActivityAction> {
    if (this.excludeChanges(service, change.path)) {
      return [];
    }
    const path = this.buildPath(change.path, service);
    const values = [];
    if (this.isKeyValueChange(change)) {
      if (change.to) {
        const items = (change.to as Array<any>).filter(kvPair => !this.shouldExclude(service, kvPair.Key));
        values.push(...this.mapKeyValuePairs(log, path, items, 'create', service, change));
      }

      if (change.from) {
        const items = (change.from as Array<{Key: string, Value: any}>).filter(kvPair => !this.shouldExclude(service, kvPair.Key));
        values.push(...this.mapKeyValuePairs(log, path, items, 'delete', service, change));
      }
    }
    else {
      if (!Array.isArray(change.to)) {
        values.push({
          id: log.id,
          changeTS: log.changeTS,
          changedBy: log.changedBy,
          activity: this.buildActivityColumn(service, path, log),
          rowNum: this.mapRowNumber(change),
          originalValue: this.mapValue(change.from, change.path[change.path.length -1]),
          newValue: this.mapValue(change.to, change.path[change.path.length -1]),
          status: 'success',
          activityType: log.action === 'update' ? 'Update' : log.action === 'delete' ? 'Delete' : 'Update',
          context: this.mapContextName(log, contextName)
        });
      }
      else if (Array.isArray(change.to)) {
        for (let i = 0; i < change.to.length; i++) {
          const newChange: ChangeItemModel = {
            type: 'update',
            path: [path, i + ''],
            to: change.to[i],
            from: change.from
          };

          values.push(...this.mapUpdateChange(log, newChange, service, contextName));
        }
      }
      }
    return values;
  }

  private mapValue(value: string | boolean | number | Array<any> | object, fieldName: string): string {
    if (value === null) {
      return '';
    }

    switch (typeof(value)) {
      case 'boolean':
        return value ? 'True' : 'False';
      case 'number':
        return value.toString();
      case 'object':
        if (Array.isArray(value)) {
          return value.map(i => i.toString()).join(', ');
        }

        return JSON.stringify(value);
      default:
        if (this.isDate(value, fieldName)) {
          const date = value.split(',').length === 2 ?
            this.dateHelperService.fromLocalDate(value.split(',')[0]) :
            this.dateHelperService.fromISO(value);
            return this.dateHelperService.toLocaleString(date, DateTime.DATETIME_SHORT);
        }

        return value;
    }
  }

  private mapKeyValuePairs(
    log: ChangeModel,
    basePath: string,
    kvPairs: Array<{Key: string, Value: any}>,
    action: 'create' | 'delete',
    service: ChangeLogServiceI,
    change: ChangeItemModel
  ): Array<UIActivityAction> {
    const isAdd = action === 'create';
    const values = [];
    for (const pair of kvPairs) {
      if ((Array.isArray(pair.Value) && this.isKeyValueArray(pair.Value[0]))) {
        for (let i = 0; i < pair.Value.length; i++) {
          if (!this.shouldExclude(service, pair.Key)) {
            values.push(
              ...this.mapKeyValuePairs(
                log,
                this.buildPath([basePath, pair.Key, i + ''], service),
                pair.Value[i],
                action,
                service,
                change
              ));
          }
        }
      }
      else if (!this.shouldExclude(service, pair.Key)) {
        values.push({
          id: log.id,
          changeTS: log.changeTS,
          changedBy: log.changedBy,
          activity: this.buildActivityColumn(service, `${basePath} . ${service.pathToFieldName(pair.Key)}`, log),
          rowNum: this.mapRowNumber(change),
          originalValue: isAdd ? '(no value)' : this.mapValue(pair.Value, pair.Key),
          newValue: isAdd ? this.mapValue(pair.Value, pair.Key) : '(no value)',
          status: 'success',
          activityType: isAdd ? 'Create' : 'Delete',
        });
      }
    }

    return values;
  }

  private buildPath(path: Array<string>, service: ChangeLogServiceI): string {
    return path.filter(item => {
      if (isNaN(parseInt(item))) {
        return item;
      }
    }).map(pathPart => {
      return service.pathToFieldName(pathPart);
    }).join(' . ');
  }

  private isDate(fieldValue: any, fieldName: string): boolean {
    const endsWith = fieldName.toLowerCase().slice(-2);
    if (['dt', 'ts'].includes(endsWith) && !!fieldValue) {
      return (new Date(fieldValue)).toString() !== 'Invalid Date';
    } else {
      return false;
    }
  }

  private isKeyValueChange(changeDto: ChangeItemModel): boolean {
    return this.isKeyValueArray(changeDto.from) || this.isKeyValueArray(changeDto.to);
  }

  private isKeyValueArray(arr: any): boolean {
    return Array.isArray(arr) && arr.length && arr.every(item => item.hasOwnProperty('Key') && item.hasOwnProperty('Value'));
  }

  private shouldExclude(service: ChangeLogServiceI, fieldName: string): boolean {
    const excludeFields = service.getExcludeFields();
    if (excludeFields.includes(fieldName)) {
      return true;
    }
    else if (!service.shouldInclude(fieldName.toLowerCase())
      && fieldName.toLowerCase().slice(-2) === 'id'
    ) {
      return true;
    }
    else {
      return false;
    }
  }
  private excludeChanges(service: ChangeLogServiceI, changePath: Array<string>): boolean {
    let exclude = false;
    changePath.forEach(change => {
      if (this.shouldExclude(service, change)) {
        exclude = true;
      }
    });
    return exclude;
  }

  private buildActivityColumn(service: ChangeLogServiceI, path: string, log: ChangeModel): string {
    if (service.buildActivityColumn) return service.buildActivityColumn(path, log);
    return `Field Name: ${path}`;
  }
}
