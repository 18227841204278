import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { cloneDeep as _cloneDeep } from 'lodash';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { EmailPattern } from '../../constants';
import {
  ApiFilterRequest,
  ComboboxFilterEvent,
  DropDownPageEvent,
  DropDownPagingData,
  IntegraSearchConfigColumn,
  IntegraSearchConfigColumnOption,
  IntegraSearchConfigRow,
  IntegraSearchFilterChanged,
  IntegraSearchOperators,
  SearchDataList
} from '../../shared-interfaces/shared-interfaces';
import { DateHelperService } from '../../shared-services/date-helper/date-helper.service';
import { FilterComboWidth } from './integra-search-enum';

const EntrySeparator = ';';

type MapValue = {
  data: Array<SearchDataList>;
  selectedValue?: SearchDataList;
  isLoading: boolean;
  pagingData?: DropDownPagingData;
  disabled?: boolean;
  disableOnSelected?: string[];
  usePageNumberInCallback?: boolean;
  pageSize?: number;
  callback?(searchString: string): Observable<Array<SearchDataList>>;
  onPageCallback?(searchString: string, pageIndicator: number, pageSize: number);
};

@Component({
  /* eslint "@angular-eslint/component-selector" : "off" */
  selector: 'integra-search',
  templateUrl: './integra-search.component.html',
  styleUrls: ['./integra-search.component.scss']
})
export class IntegraSearchComponent implements OnInit, OnChanges {

  @ViewChild('anchor') public anchor: ElementRef;
  @ViewChild('popup', { read: ElementRef }) public popup: ElementRef;

  @Input() searchConfig: IntegraSearchConfigRow[] = [];
  @Input() placeHolder: string;
  @Input() searchViewMode: string;
  @Input() advancedEnabled = true;
  @Input() useWildCards = true;
  @Input() filterComboWidth: string;
  @Input() disabled = false;
  @Input() closeButton = false;
  @Input() defaultPropertySearch: { key: string, property: string } = { key: 'WildSearch', property: 'wildSearch' };
  @Input() minInputChars = 0;

  @Output() applyFilterEvent: EventEmitter<ApiFilterRequest[]> = new EventEmitter();
  @Output() searchChanges: EventEmitter<string> = new EventEmitter();
  @Output() filterChange: EventEmitter<IntegraSearchFilterChanged> = new EventEmitter();

  private applyFilterDebounced: Subject<any> = new Subject<any>();

  public get FilterWidthResult(): typeof FilterComboWidth {
    return FilterComboWidth;
  }
  public showAdvanced = false;
  public config: IntegraSearchConfigRow[];
  public advancedSearchInput: string;
  public advancedSearchChanged: Subject<string> = new Subject<string>();
  public dataLoader = new Map<string, MapValue>();
  public readOnlyAnchor = false;
  public valuesOnOpen = new Map<string, any>();
  public patternForEmail = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';


  constructor(
    private dateHelperService: DateHelperService,
  ) {
    this.advancedSearchChanged.pipe(
      debounceTime(1000), // wait 1 sec after the last event before emitting last event
      distinctUntilChanged())     // only emit if value is different from previous value
      .subscribe(model => {
        this.parseToAdvanced();
        this.searchChanges.emit(model);
      });
    this.applyFilterDebounced.pipe(
      debounceTime(500), // wait 1 sec after the last event before emitting last event
      distinctUntilChanged())
      .subscribe((filterHeaders) => {
        this.applyFilterEvent.emit(filterHeaders);
      });
  }

  ngOnInit(): void {
    const shouldApplyFilter = this.evaluateColumns();
    if (shouldApplyFilter) {
      this.applyFilters();
    }
  }

  private evaluateColumns(): boolean {
    let shouldApplyFilter = false;
    this.config = _cloneDeep(this.searchConfig);
    if (this.config) {
      this.config.forEach(configRow => {
        configRow.columns.forEach(column => {
          if (!column.operator) {
            column.operator = IntegraSearchOperators.equals;
          }
          if (column.callback) {
            this.dataLoader.set(column.key, {
              data: [],
              callback: column.callback,
              isLoading: false,
              disabled: column.disabled,
              disableOnSelected: column.disableOnSelected ? column.disableOnSelected : [],
            });
          }
          if (!!column.pagingConfig) {
            this.dataLoader.set(column.key, {
              data: [],
              isLoading: false,
              pagingData: new DropDownPagingData(),
              usePageNumberInCallback: column.pagingConfig.usePageNumberInCallback,
              pageSize: column.pagingConfig.pageSize,
              onPageCallback: column.pagingConfig.onPageCallback,
              disabled: column.disabled,
              disableOnSelected: column.disableOnSelected ? column.disableOnSelected : [],
            });
          }
          if (column.type === 'combobox' && column.data) {
            this.dataLoader.set(column.key, {
              data: column.data,
              callback: (_) => of(column.data),
              isLoading: false,
              disabled: column.disabled,
              disableOnSelected: column.disableOnSelected ? column.disableOnSelected : [],
            });
          }
          if (column.value !== null && column.value !== undefined) {
            if (column.fetchSingleItem) {
              this.fetchDefaultValues(column);
            } else {
              shouldApplyFilter = true;
            }
          }
        });
      });
    }
    return shouldApplyFilter;
  }


  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.searchViewMode?.currentValue) {
      this.handleViewModeChanges(simpleChanges.searchViewMode.currentValue);
    }
  }

  fetchDefaultValues(column: IntegraSearchConfigColumn): void {
    column.fetchSingleItem(column.value).subscribe(response => {
      if (column.type === 'combobox') {
        this.dataLoader.get(column.key).data = [response];
        this.handleComboSelection(response, column.key);
      } else {
        column.value = response;
      }
      this.applyFilters();
    });
  }

  handleViewModeChanges(viewMode: string): void {
    switch (viewMode) {
      case 'adv-search-wide':
        this.readOnlyAnchor = true;
        break;
      case 'adv-search-extra-wide':
        this.readOnlyAnchor = true;
        break;
      default:
        this.readOnlyAnchor = false;
    }
  }

  onAdvancedSearchChanged(): void {
    if (
      this.advancedSearchInput.length === 0 ||
      this.advancedSearchInput.length >= this.minInputChars
    ) {
      this.advancedSearchChanged.next(this.advancedSearchInput);
    }
  }

  clearFilters(): void {
    this.advancedSearchInput = '';
    this.dataLoader.forEach(loader => loader.selectedValue = null);
    this.parseToAdvanced();
    // publish filter changed events for all filters
    this.config.forEach(row => {
      row.columns.forEach(column => {
        this.filterChange.emit({ columnKey: column.key, fieldValue: column.value });
      });
    });
  }

  resetFilters(): void {
    this.advancedSearchInput = '';
    this.config.forEach(config => config.columns.forEach(control => {
      if (control.initialValue) {
        control.value = control.initialValue;
      }
    }));
    this.applyFilters();
  }

  isValid(value: any): boolean {
    if (value instanceof Array && value.length === 0) {
      return false;
    }
    return !!value && value !== '';
  }

  public togglePopup(show?: boolean): void {
    this.showAdvanced = show !== undefined ? show : !this.showAdvanced;
    if (this.showAdvanced) {
      // capture values so that we can track if the filter changed
      this.captureValues();
    }
  }

  applyFilters(updateSearchString: boolean = true, raiseEvent: boolean = true, closePopup: boolean = true): void {
    let text = '';
    const filterHeaders = [];
    this.config.forEach((row) => {
      row.columns.forEach((column) => {
        switch (column.key) {
          case this.defaultPropertySearch.key:
            if (this.isValid(column.value) && column.value.length >= 3 && (column.type !== 'email' ||
              (column.type === 'email' && EmailPattern.test(column.value)))) {
              text = `${text}${column.value}${EntrySeparator} `;
              const filterStr = {
                property: column.property !== this.defaultPropertySearch.property ? column.property : this.defaultPropertySearch.property,
                operator: column.operator,
                value: '',
                group: column.group ? column.group : null
              };
              filterStr.value = this.useWildCards ? column.value + '%' : column.value;
              filterHeaders.push(filterStr);
            }
            break;
          default:
            if (this.isValid(column.value)) {
              let useValueForFilter: string;
              let useDisplayValue: string;
              switch (column.type) {
                case 'date':
                  let dateValue = this.dateHelperService.fromLocalDate(column.value);
                  dateValue = column.endOfDay ? this.dateHelperService.addEndOfDayTime(dateValue) : dateValue;
                  useValueForFilter =
                    column.dateTime ? this.dateHelperService.toISO(dateValue) : this.dateHelperService.toISODate(dateValue);
                  useDisplayValue = this.dateHelperService.toLocaleString(dateValue);
                  if (typeof column.value === 'string') {
                    column.value = dateValue;
                  }
                  break;
                case 'checkbox':
                  useValueForFilter = column.value;
                  useDisplayValue = this.mapToLabels(column.value, column.options);
                  break;
                case 'combobox':
                  useValueForFilter = column.value.id;
                  useDisplayValue = column.value.displayName;
                  break;
                case 'number':
                  useValueForFilter = column.value.toString();
                  useDisplayValue = column.value.toString();
                  break;
                default:
                  useValueForFilter = column.value;
                  useDisplayValue = column.value;
              }
              text = `${text}${column.key}:${useDisplayValue}${EntrySeparator} `;
              filterHeaders.push({ operator: column.operator, property: column.property, value: useValueForFilter, group: column.group });
            }
            break;
        }
      });
    });
    if (raiseEvent) {
      this.applyFilterDebounced.next(filterHeaders);
    }
    if (updateSearchString) {
      this.advancedSearchInput = text.substr(0, text.length - 2);
    }
    if (closePopup) {
      this.showAdvanced = false;
    }
  }

  parseToAdvanced(): void {
    const valuesMap: Map<string, string> = new Map<string, string>();
    if (this.advancedSearchInput > '') {
      const searchPairs = this.advancedSearchInput.split(';');
      searchPairs.forEach(searchPair => {
        if (searchPair > '' && searchPair.indexOf(':') > 0) {
          const searchPairItem = searchPair.split(':');
          valuesMap.set(searchPairItem[0].trim(), searchPairItem[1].trim());
        } else {
          valuesMap.set(this.defaultPropertySearch.key, searchPair.trim());
        }
      });
    }

    this.config.forEach((row) => {
      row.columns.forEach((column) => {
        if (valuesMap.has(column.key)) {
          const value = valuesMap.get(column.key);
          switch (column.type) {
            case 'date':
              column.value = this.dateHelperService.fromLocalDate(value);
              break;
            case 'checkbox':
              column.value = this.mapToValues(value, column.options);
              if (column.value === '') {
                column.value = void 0;
              }
              break;
            default:
              column.value = valuesMap.get(column.key);
          }
        } else {
          column.value = null;
        }
      });
    });

    this.applyFilters(false);

  }

  private mapToLabels(values: string, options: IntegraSearchConfigColumnOption[]): string {
    let labelList = '';
    values.split(',').forEach(valueEntry => {
      labelList = labelList + options.find(optionEntry => optionEntry.key === valueEntry).label + ',';
    });
    return labelList.substr(0, labelList.length - 1);
  }

  private mapToValues(labels: string, options: IntegraSearchConfigColumnOption[]): string {
    let valueList = '';
    labels.split(',').forEach(labelEntry => {
      const option = options.find(optionEntry => optionEntry.label === labelEntry);
      if (option) {
        valueList = valueList + option.key + ',';
      }
    });
    return valueList.substr(0, valueList.length - 1);
  }

  checkOption(option: IntegraSearchConfigColumnOption, column: IntegraSearchConfigColumn, checkEvent: any): void {
    if (checkEvent.target.checked) {
      if (!column.value) {
        column.value = '';
      }
      if (column.value.split(',').indexOf(option.key) < 0) {
        if (column.value > '') {
          column.value = column.value + ',';
        }
        column.value = column.value + option.key;
      }
    } else if (!checkEvent.target.checked && column.value) {
      if (column.value.indexOf(option.key) >= 0) {
        const values = column.value.split(',');
        values.splice(values.indexOf(option.key), 1);
        column.value = values.join(',');
        if (column.value === '') {
          column.value = null;
        }
      }
    }
  }

  isChecked(option: IntegraSearchConfigColumnOption, column: IntegraSearchConfigColumn): string {
    return column.value && column.value.split(',').indexOf(option.key) >= 0 ? '' : null;
  }

  public handleComboSelection = (selectedValue: SearchDataList, columnKey: string) => {
    const currentLoader = this.dataLoader.get(columnKey);
    if (currentLoader) {
      currentLoader.selectedValue = selectedValue ? selectedValue : null;
      currentLoader.disableOnSelected?.forEach((value) => {
        this.dataLoader.get(value).disabled = !!currentLoader.selectedValue;
      });
    }
    for (const configEntry of this.config) {
      const col = configEntry.columns.find(column => column.key === columnKey);
      if (col) {
        col.value = selectedValue ? selectedValue : null;
        break;
      }
    }
  }

  public handleComboFilterChange = (filter: ComboboxFilterEvent, columnKey: string) => {
    const currentLoader = this.dataLoader.get(columnKey);
    if (!currentLoader.pagingData) {
      currentLoader.isLoading = true;
      currentLoader.callback(filter.filter).pipe(
        finalize(() => {
          currentLoader.isLoading = false;
        })
      ).subscribe((dataList) => {
        currentLoader.data = dataList;
        if (dataList.length === 0) {
          for (const configEntry of this.config) {
            const col = configEntry.columns.find(column => column.key === columnKey);
            if (col) {
              col.value = null;
              break;
            }
          }
        }
      });
    } else {
      currentLoader.pagingData.reset();
      currentLoader.isLoading = true;
      currentLoader.onPageCallback(filter.filter, 0, currentLoader.pageSize)
        .pipe(
          finalize(() => {
            currentLoader.isLoading = false;
          })
        )
        .subscribe(dataList => {
          currentLoader.pagingData.updateData(dataList, filter.filter);
        });
    }
  }

  public handleComboPageChange = (criteria: DropDownPageEvent, columnKey: string) => {
    const currentLoader = this.dataLoader.get(columnKey);
    if (!currentLoader.pagingData) {
      return;
    }

    currentLoader.onPageCallback(criteria.filter,
      currentLoader.usePageNumberInCallback ? criteria.pageNumber : criteria.restartRowNumber,
      criteria.pageSize)
      .subscribe(dataList => {
        currentLoader.pagingData.updateData(dataList, criteria.filter);
      });
  }

  public patchConfig(patchValues: Map<string, Map<string, any>>): void {
    patchValues.forEach((valueMap, columnKey) => {
      const column = this.getColumnByKey(columnKey);
      valueMap.forEach((value, property) => {
        column[property] = value;
      });
    });
    this.applyFilters(true, false, false);
  }

  private getColumnByKey(s: string): IntegraSearchConfigColumn {
    let foundColumn: IntegraSearchConfigColumn = null;
    this.config.forEach((row) => {
      row.columns.forEach((column) => {
        if (column.key === s) {
          foundColumn = column;
        }
      });
    });
    return foundColumn;
  }

  public handleLookupSelection($event: any, key: string): void {
    // check if the filter actually changed
    if (this.valuesOnOpen.get(key) !== $event) {
      // store new value for future checks
      this.valuesOnOpen.set(key, $event);
      this.filterChange.emit({ columnKey: key, fieldValue: $event });
    }
  }

  private captureValues(): void {
    const capturedValues = new Map<string, string>();
    this.config.forEach((row) => {
      row.columns.forEach((column) => {
        capturedValues.set(column.key, column.value);
      });
    });
    this.valuesOnOpen = capturedValues;
  }
}
