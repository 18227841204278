<kendo-grid [kendoGridBinding]="data" [height]="height" [groupable]="true">
  <kendo-grid-column
    *ngFor="let column of columns"
    title="{{ column.title }}"
    [field]="column.field"
    [headerStyle]="getHeaderStyle(column.widthStyle)"
    [style]="getStyle(column.widthStyle)"
  >
    <ng-template
      [ngSwitch]="column.type"
      kendoGridCellTemplate
      let-dataItem
      let-rowIndex="rowIndex"
    >
      <span *ngSwitchCase="'date'">{{
        dataItem[column.field]
          | date : "MM/dd/yyyy, h:mm a" : null : dateLocalePipe
      }}</span>
      <span *ngSwitchCase="'text'"
        class="grouped-grid-text-col"
      >{{ dataItem[column.field] }}</span>
      <span *ngSwitchCase="'html'" [innerHtml]="dataItem[column.field] | safeHtml"></span>
      <span *ngSwitchCase="'pill'">
        <prism-pill
          *ngIf="!dataItem[column.field].value"
          [status]="dataItem[column.field]"
          class="d-flex"
        ></prism-pill>
      </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
